<template>
  <div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" :class="{ 'font-weight-bold font-italic': sourcePair == 'IDR' }">IDR</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" id="kalk_IDR" :value="IDR" @input="changeHarga($event, 'IDR')" placeholder="IDR" />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" :class="{ 'font-weight-bold font-italic': sourcePair == 'USDT' }">USDT</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" id="kalk_USDT" :value="USDT" @input="changeHarga($event, 'USDT')" placeholder="USDT" />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" :class="{ 'font-weight-bold font-italic': sourcePair == 'ETH' }">ETH</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" id="kalk_ETH" :value="ETH" @input="changeHarga($event, 'ETH')" placeholder="ETH" />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" :class="{ 'font-weight-bold font-italic': sourcePair == 'BTC' }">BTC</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" id="kalk_BTC" :value="BTC" @input="changeHarga($event, 'BTC')" placeholder="BTC" />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" :class="{ 'font-weight-bold font-italic': sourcePair == 'BNB' }">BNB</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" id="kalk_BNB" :value="BNB" @input="changeHarga($event, 'BNB')" placeholder="BNB" />
      </div>
    </div>
    <div class="text-muted text-center mt-2">Binance Last Fetch : {{ new Date(this.$store.state.binance.lastFetch).toLocaleString("id-ID") }}</div>
    <div class="text-center">
      <button :disabled="loading" class="btn btn-info btn-xs" @click="updateBinance">
        <div v-if="loading" class="spinner-border spinner-border-sm"></div>
        Reload Binance &amp; Rekalkulasi
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Kalkulator",
  props: {
    pair: String,
    harga: String,
    trigger: Boolean,
    defaultSource: String,
  },
  data() {
    return {
      IDR: 0,
      USDT: 0,
      ETH: 0,
      BTC: 0,
      BNB: 0,
      BTCUSDT: 0,
      BTCBIDR: 0,
      ETHBTC: 0,
      ETHBIDR: 0,
      ETHUSDT: 0,
      BNBETH: 0,
      USDTBIDR: 0,
      BNBBTC: 0,
      BNBBIDR: 0,
      BNBUSDT: 0,
      sourcePair: "IDR",
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["listBookTicker"]),
  },
  watch: {
    trigger: {
      handler() {
        this.sourcePair = this.defaultSource!==undefined ? this.defaultSource : "USDT";
        this.[this.sourcePair] = this.harga;
        this.updateBinance();
        // this.hitung("USDT", this.harga);
        // this.hitung(this.pair, this.harga);
      },
      immediate: true,
    },
  },
  methods: {
    updateBinance() {
      this.loading = true;
      this.$store.dispatch("updateBookTicker").then(() => {
        this.hitung(this.sourcePair, this[this.sourcePair]);
        this.loading = false;
      });
    },
    changeHarga(event, pair) {
      // this.ETH = event.target.value;
      // console.log("etv",event.target.value);
      // console.log("pair",this.ETH);
      // console.log("pair1",pair);
      this.hitung(pair, event.target.value);
    },
    hitung(target_pair, target_harga) {
      this.sourcePair = target_pair;
      this.updateKonversi();
      target_pair = target_pair == "BUSD" ? "USDT" : target_pair;
      this[target_pair] = target_harga;
      switch (target_pair) {
        case "ETH":
          // console.log("a",parseFloat(this.[target_pair]));
          // console.log("b",parseFloat(this.ETHUSDT));
          // console.log("a*b",parseFloat(parseFloat(this.[target_pair]) * parseFloat(this.ETHUSDT)));
          this.BTC = parseFloat(parseFloat(this[target_pair]) * parseFloat(this.ETHBTC)).toFixed(8);
          this.IDR = parseFloat(parseFloat(this[target_pair]) * parseFloat(this.ETHBIDR)).toLocaleString("en", { maximumFractionDigits: 2 });
          this.USDT = parseFloat(parseFloat(this[target_pair]) * parseFloat(this.ETHUSDT)).toFixed(8);
          this.BNB = parseFloat(parseFloat(this[target_pair]) / parseFloat(this.BNBETH)).toFixed(8);
          break;
        case "BTC":
          this.ETH = parseFloat(parseFloat(this[target_pair]) / parseFloat(this.ETHBTC)).toFixed(8);
          this.IDR = parseFloat(parseFloat(this[target_pair]) * parseFloat(this.BTCBIDR)).toLocaleString("en", { maximumFractionDigits: 2 });
          this.USDT = parseFloat(parseFloat(this[target_pair]) * parseFloat(this.BTCUSDT)).toFixed(8);
          this.BNB = parseFloat(parseFloat(this[target_pair]) / parseFloat(this.BNBBTC)).toFixed(8);
          break;
        case "IDR":
          this.ETH = parseFloat(parseFloat(this[target_pair]) / parseFloat(this.ETHBIDR)).toFixed(8);
          this.BTC = parseFloat(parseFloat(this[target_pair]) / parseFloat(this.BTCBIDR)).toFixed(8);
          this.USDT = parseFloat(parseFloat(this[target_pair]) / parseFloat(this.USDTBIDR)).toFixed(8);
          this.BNB = parseFloat(parseFloat(this[target_pair]) / parseFloat(this.BNBBIDR)).toFixed(8);
          break;
        case "USDT":
          // console.log("a",parseFloat(this.[target_pair]));
          // console.log("b",parseFloat(this.ETHUSDT));
          // console.log("a/b",parseFloat(parseFloat(this.[target_pair]) / parseFloat(this.ETHUSDT)));
          this.ETH = parseFloat(parseFloat(this[target_pair]) / parseFloat(this.ETHUSDT)).toFixed(8);
          this.BTC = parseFloat(parseFloat(this[target_pair]) / parseFloat(this.BTCUSDT)).toFixed(8);
          this.IDR = parseFloat(parseFloat(this[target_pair]) * parseFloat(this.USDTBIDR)).toLocaleString("en", { maximumFractionDigits: 2 });
          this.BNB = parseFloat(parseFloat(this[target_pair]) / parseFloat(this.BNBUSDT)).toFixed(8);
          break;
        case "BNB":
          this.ETH = parseFloat(parseFloat(this[target_pair]) * parseFloat(this.BNBETH)).toFixed(8);
          this.BTC = parseFloat(parseFloat(this[target_pair]) * parseFloat(this.BNBBTC)).toFixed(8);
          this.IDR = parseFloat(parseFloat(this[target_pair]) * parseFloat(this.BNBBIDR)).toLocaleString("en", { maximumFractionDigits: 2 });
          this.USDT = parseFloat(parseFloat(this[target_pair]) * parseFloat(this.BNBUSDT)).toFixed(8);
          break;
      }
    },
    getAskPrice(pair) {
      let data = this.listBookTicker.find(item => item.symbol == pair);
      if (data == undefined) {
        return 0;
      } else {
        return data.askPrice;
      }
    },
    updateKonversi() {
      this.BTCUSDT = this.getAskPrice("BTCUSDT");
      this.BTCBIDR = this.getAskPrice("BTCBIDR");
      this.ETHBTC = this.getAskPrice("ETHBTC");
      this.ETHBIDR = this.getAskPrice("ETHBIDR");
      this.ETHUSDT = this.getAskPrice("ETHUSDT");
      this.BNBETH = this.getAskPrice("BNBETH");
      this.USDTBIDR = this.getAskPrice("USDTBIDR");
      this.BNBBTC = this.getAskPrice("BNBBTC");
      this.BNBBIDR = this.getAskPrice("BNBBIDR");
      this.BNBUSDT = this.getAskPrice("BNBUSDT");
    },
  },
};
</script>
